.navbar {
display: flex !important;
flex-flow: row wrap !important;
justify-content: space-between !important;
background: transparent !important;

}

.navbar.active {
    background: #121132 !important;
}

.navbarBack {
    display: flex !important;
    flex-flow: row wrap !important;
    justify-content: space-between !important;
    background-color: #121132  !important; 
    height: 100px !important;
}